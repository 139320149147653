import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // Flag "semáforo" para verificar se o token está em processo de refresh
  private isRefreshing = false;

  private _executando = false;

  // Utilizado para salvar o token "refreshado" de imediato e emitir
  // para as requisições que estavam no aguardo do refresh de token
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private _authService: AuthService, private _router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // CÓDIGO QUE REDIRECIONA SISTEMA PARA TELA DE LOGIN 1 SEGUNDO APÓS EXPIRAR TOKEN
    if (!this._executando && this._authService.accessToken) {
      this._executando = true;

      const source = interval(1000);

      source.subscribe((val) => {
        if(this._authService.accessToken) {
          const now = Math.floor(Date.now() / 1000); // Pega o timestamp referente ao momento agora
          if((AuthUtils.getTokenExpiration(this._authService.accessToken) - now) / 60 <= 0) {
            this._authService.signOut().subscribe(() => {
              this._router.navigate(['/sign-in']);
            });
          }
        }
      });
    }

    // Para prosseguir rotas que já foram informadas o authorization
    if (request.headers.get('authorization')) {
      return next.handle(request);
    }

    // Para prosseguir rotas que não devem ter token
    if (request.headers.get('NoToken')) {
      request.headers.delete('NoToken');
      return next.handle(request);
    }

    const token = this._authService.accessToken;
    if (token && !AuthUtils.isTokenExpired(token)) {
      const exp = AuthUtils.getTokenExpiration(token) - 300;
      const now = Math.floor(Date.now() / 1000); // Pega o timestamp referente ao momento agora

      // Verifica se o momento de expiração (-5 min) é maior que o momento atual.
      if (exp > now) {
        request = this.addToken(request, token);
      } else {
        request = this.addToken(request, token);
        this.handleRefreshToken(request, next); // Se está próximo a expirar, hora de executar o refresh
      }
    } else if (token && AuthUtils.isTokenExpired(token)) {
      this._authService.signOut();
    }

    // Verefica se e uma requisição do proxy
    if (request.url.includes('integrador-proxy')) {
      return next.handle(request);
    }

    return next.handle(request);
  }

  // Retorna um clone da requisição com o token adicionado ao header
  private addToken(request: HttpRequest<any>, token: string) {
    const content = request.headers.get('Content-Type')
      ? request.headers.get('Content-Type')
      : 'application/json';

    if (content !== 'none') {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': content,
        },
      });
    }

    return request.clone({
      headers: request.headers.delete('Content-Type', 'none'),
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // Efetua o refresh do token
  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._authService.refreshToken().subscribe((token) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(token);
        return next.handle(request);
      });
    }

    return this.refreshTokenSubject
      .pipe(
        filter((token) => token != null),
        take(1),
        switchMap((newToken) => {
          // Next passando a requisição com o novo token gerado
          return next.handle(this.addToken(request, newToken));
        })
      )
      .subscribe(); // Fim Senão
  } // Fim handleRefresh
} // Fim TokenInterceptor
