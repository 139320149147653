<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full object-cover"
      *ngIf="user.imageProfile"
      [src]="'data:image/png;base64, ' + user.imageProfile"
    />

    <mat-icon
      *ngIf="!user.imageProfile"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <div class="px-4">
    <span class="flex flex-col leading-none">
      <span>Você está logado como</span>
      <span class="mt-1.5 text-lg font-medium font-bold">
        {{ user.nome }}
      </span>
      <span class="text-sm mt-1 font-medium font-bold text-gray-500 dark:text-gray-300">
        {{ user.perfil.nome | titlecase }}
      </span>
    </span>
  </div>

  <mat-divider class="my-2"></mat-divider>

  <button mat-menu-item (click)="openSettingsDrawer()">
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span>Configurações</span>
  </button>

  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>
