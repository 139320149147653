<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.admin" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="w-24" src="assets/images/logo/logo-text-on-dark.svg" alt="Logo image" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <img class="dark:hidden w-24" src="assets/images/logo/logo-text.svg" />
          <img class="hidden dark:flex w-24" src="assets/images/logo/logo-text-on-dark.svg" />
        </div>
        <img class="flex lg:hidden w-8" src="assets/logos/corebase.png" />
      </div>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation.admin">
      </fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <!-- <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <notifications></notifications> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="flex flex-col sm:flex-row items-center justify-between w-full sm:h-24 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <section class="font-medium text-secondary" name="endereco">
      <p>Mardonio S Costa Desenvolvedor Sr - Copyright ©</p>
      <p>Coordenadoria de Tecnologia da Informação</p>
      <p>2021 - Todos os Direitos Reservados</p>
    </section>

    <section name="versao" class="font-medium text-secondary">
      <p>
        Versão: <strong>{{ nomeSistema }} &copy; {{ currentYear }} </strong>
      </p>
    </section>
  </div>
</div>
